import Vue from 'vue'
import App from './App.vue'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueGeolocation from 'vue-browser-geolocation'
import VueRouter from 'vue-router'
import Map from '@/components/map/Map';
import Index from '@/pages/Index';
import VueGtm from '@gtm-support/vue2-gtm'

const router = new VueRouter({
  routes: [
    {path: '/', component: Index},
    {path: '/map', component: Map, name: 'map'},
  ],
  mode: 'history',
})
Vue.use(VueRouter)
Vue.use(VueGeolocation)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC7T-6rLjBjxQaxXxXCDzEmrHwK0sGddRQ',
    libraries: 'places',
  },
  installComponents: true
})
Vue.use(VueGtm, {
  id: 'GTM-TDB2THB',
  queryParams: {
  },
  defer: false,
  compatibility: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false,
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
