<template>
  <RouterView>
    <div id="app">
      <Map></Map>
    </div>
  </RouterView>

</template>

<script>
import Map from './components/map/Map.vue'

export default {
  name: 'App',
  components: {
    Map,
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap');
  * {
    margin: 0;
    padding: 0;
  }
body, input, form, select, textarea, button {
  font-family: 'Source Sans Pro';
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


</style>
