var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"map"}},[_c('GmapMap',{ref:"mapRef",staticClass:"map",staticStyle:{"height":"100vh"},attrs:{"center":_vm.position,"zoom":_vm.zoom,"map-type-id":"terrain","options":{/*styles: mapStyle*/}},on:{"zoom_changed":_vm.onZoomChange}},[_c('Gmap-autocomplete',{staticClass:"search",attrs:{"options":{fields: ['geometry']},"selectFirstOnEnter":true},on:{"place_changed":_vm.updatePlace}}),_vm._l((_vm.mappedSelectedLocations),function(location){return _c('GmapMarker',{key:("selected-location-" + (location.id)),attrs:{"position":location.realCoordinates || location.coordinates,"clickable":true,"draggable":false,"icon":location.icon},on:{"click":function($event){return _vm.toggleInfoWindow(location.id)}}})}),(_vm.zoom > 12)?_vm._l((_vm.notFetchedLocations),function(location){return _c('GmapCircle',{key:("circle-" + (location.id)),attrs:{"center":location.coordinates,"radius":200,"options":{fillColor:'#86179d',fillOpacity:0.4, strokeColor: '#33058d', strokeOpacity: 0.8, strokeWeight: 2}}})}):_vm._e(),_vm._l((_vm.clusters),function(location){return _c('GmapMarker',{key:("cluster-" + (location.id)),attrs:{"position":location.realCoordinates || location.coordinates,"clickable":true,"draggable":false,"icon":{
          url: _vm.clusterIcon,
          labelOrigin: {x: 12, y: 13},
        },"label":{
          text: location.pointCount >= 9999 ? '99+' : String(location.pointCount),
          fontWeight: '800',
          fontSize: '11px',
          color: _vm.clusterTextColor
        }},on:{"click":function($event){return _vm.increaseZoom(location)}}})}),(_vm.currentlyOpenedLocation)?_c('GmapInfoWindow',{attrs:{"options":_vm.infoOptions,"position":_vm.currentlyOpenedLocation.realCoordinates || _vm.currentlyOpenedLocation.coordinates,"opened":!!_vm.currentlyOpenedLocationId},on:{"closeclick":_vm.closeInfo}},[(_vm.currentlyOpenedLocation.fetched)?_c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"description"},[_c('div',{staticClass:"power"},[_vm._v("Moc: "+_vm._s(_vm.currentlyOpenedLocation.power)+" kW")])])])]):_c('div',[_vm._v("Pobieranie danych...")])]):_vm._e()],2),_c('div',{staticClass:"backgroundGps",on:{"click":_vm.GPS}},[_c('div',{staticClass:"GPS"})]),_c('div',{class:['popup-use', {'show': _vm.popupUse}]},[_vm._v(" Zły token lub przekroczony limit. Skontaktuj się z administratorem na adres "),_c('b',[_vm._v("czesc@apkeo.pl")])]),_c('Logos')],1)}
var staticRenderFns = []

export { render, staticRenderFns }