<template>
  <div class="logo-wrapper">
    <img class="logo" src="/img/sunday-logo.png"/>
<!--    <img class="logo-apkeo" src="/img/apkeo-logo.svg"/>-->
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
  .logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
  }

  .logo {
    height: 48px;
  }

  .logo-apkeo {
    height: 32px;
    margin-left: 30px;
  }
</style>