import axios from 'axios'

const API_URL = 'https://v2.virus.sundaypolska.pl/api'
// const API_URL = 'http://localhost/api'

export class ApiGoogleMaps {
  static async getLocations({latMin, latMax, lngMin, lngMax, zoom}) {
    try {
      const response = await axios.get(API_URL + '/locations', {
        params: {
          latMin,
          latMax,
          lngMin,
          lngMax,
          zoom,
          source: 'dth'
        }
      });
      return response.data.data
    } catch (error) {
      console.error(error);
      throw error
    }
  }

  static async getLocation({id, token}) {
    try {
      const response = await axios.get(API_URL + `/location/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response);
      return response.data
    } catch (error) {
      console.error(error);
      throw error
    }
  }
}
