<template>
  <div id="map">
    <GmapMap
      :center="position"
      :zoom="zoom"
      map-type-id="terrain"
      style="height: 100vh;"
      class="map"
      ref="mapRef"
      @zoom_changed="onZoomChange"
      :options="{/*styles: mapStyle*/}"
    >
      <Gmap-autocomplete
      class="search"
      :options="{fields: ['geometry']}"
      :selectFirstOnEnter="true"
      @place_changed="updatePlace"
      />
      <GmapMarker
        :key="`selected-location-${location.id}`"
        v-for="location in mappedSelectedLocations"
        :position="location.realCoordinates || location.coordinates"
        :clickable="true"
        :draggable="false"
        @click="toggleInfoWindow(location.id)"
        :icon="location.icon"
      />
      <template v-if="zoom > 12">
        <GmapCircle
          v-for="(location) in notFetchedLocations"
          :key="`circle-${location.id}`"
          :center="location.coordinates"
          :radius="200"
          :options="{fillColor:'#86179d',fillOpacity:0.4, strokeColor: '#33058d', strokeOpacity: 0.8, strokeWeight: 2}"
        />
      </template>
      <GmapMarker
        :key="`cluster-${location.id}`"
        v-for="location in clusters"
        :position="location.realCoordinates || location.coordinates"
        :clickable="true"
        :draggable="false"
        @click="increaseZoom(location)"
        :icon="{
          url: clusterIcon,
          labelOrigin: {x: 12, y: 13},
        }"
        :label="{
          text: location.pointCount >= 9999 ? '99+' : String(location.pointCount),
          fontWeight: '800',
          fontSize: '11px',
          color: clusterTextColor
        }"
      >
      </GmapMarker>
        <GmapInfoWindow
          v-if="currentlyOpenedLocation"
          :options="infoOptions"
          :position="currentlyOpenedLocation.realCoordinates || currentlyOpenedLocation.coordinates"
          :opened="!!currentlyOpenedLocationId"
          @closeclick="closeInfo"
        >
          <div v-if="currentlyOpenedLocation.fetched">
<!--            <div class="bodyContent">-->
<!--              <p>{{currentlyOpenedLocation.id}}</p>-->
<!--            </div>-->
            <div class="container">
              <div class="description">
                <div class="power">Moc: {{currentlyOpenedLocation.power}} kW</div>
              </div>
<!--              <div class="photos">-->
<!--                <div class="photo1"></div>-->
<!--                <div class="photo2"></div>-->
<!--                <div class="photo3"></div>-->
<!--              </div>-->
            </div>
          </div>
          <div v-else>Pobieranie danych...</div>
        </GmapInfoWindow>
    </GmapMap>
    <div class="backgroundGps" @click="GPS"><div class="GPS"/></div>
    <div :class="['popup-use', {'show': popupUse}]">
      Zły token lub przekroczony limit. Skontaktuj się z administratorem na adres <b>czesc@apkeo.pl</b>
    </div>
    <Logos/>
  </div>
</template>

<script>
  import {gmapApi} from 'vue2-google-maps'
  import {ApiGoogleMaps} from '@/services/ApiGoogleMaps'
  import Logos from '@/components/Logos'
  import mapStyle from '../../config/map-style.json'
  import {LocationType} from "@/enum/LocationType";
  const pointPVIconUrlMap = new Map([
    ['hybrid', '/img/markers/marker-point-white.png'],
    ['satellite', '/img/markers/marker-point-white.png'],
    ['terrain', '/img/markers/marker-point.png'],
    ['roadmap', '/img/markers/marker-point.png']
  ])

  const pointPVSecondaryUrlMap = new Map([
    ['hybrid', '/img/markers/marker-point-white.png'],
    ['satellite', '/img/markers/marker-point-white.png'],
    ['terrain', '/img/markers/marker-point2.png'],
    ['roadmap', '/img/markers/marker-point2.png']
  ])

  const pointHPIconUrlMap = new Map([
    ['hybrid', '/img/markers/marker-point-white.png'],
    ['satellite', '/img/markers/marker-point-white.png'],
    ['terrain', '/img/markers/marker-point3.png'],
    ['roadmap', '/img/markers/marker-point3.png']
  ])

  const clusterIconUrlMap = new Map([
    ['hybrid', '/img/markers/marker-cluster-white.png'],
    ['satellite', '/img/markers/marker-cluster-white.png'],
    ['terrain', '/img/markers/marker-cluster.png'],
    ['roadmap', '/img/markers/marker-cluster.png']
  ])

  // const clusterIconUrlMap = new Map([
  //   ['hybrid', '/img/markers/marker-cluster-white.png'],
  //   ['satellite', '/img/markers/marker-cluster-white.png'],
  //   ['terrain', '/img/markers/marker-cluster-dot.png'],
  //   ['roadmap', '/img/markers/marker-cluster-dot.png']
  // ])
  //
  // const pointIconUrlMap = clusterIconUrlMap

  const clusterTextColorMap = new Map([
    ['hybrid', '#87189d'],
    ['satellite', '#87189d'],
    ['terrain', '#ffffff'],
    ['roadmap', '#ffffff']
  ])

  export default {
    computed: {
      google: gmapApi,
      currentlyOpenedLocation() {
        return this.locations.find(location => {
          return location.id === this.currentlyOpenedLocationId
        })
      },
      unfetchedLocations() {
        return this.locations.filter(location => !location.fetched)
      },
      clusterIcon() {
        return clusterIconUrlMap.get(this.currentMapTypeId)
      },
      pointPVIcon() {
        return pointPVIconUrlMap.get(this.currentMapTypeId)
      },
      pointPVSecondaryIcon() {
        return pointPVSecondaryUrlMap.get(this.currentMapTypeId)
      },
      pointHPIcon() {
        return pointHPIconUrlMap.get(this.currentMapTypeId)
      },
      clusterTextColor() {
        return clusterTextColorMap.get(this.currentMapTypeId)
      },
      clusters() {
        return this.locations.filter(location => location.isCluster)
      },
      selectedLocations() {
        return this.locations.filter(location => !location.isCluster)
      },
      mappedSelectedLocations() {
        const adjustedLocations = [];
        const seenCoordinates = new Set();

        for (const location of this.selectedLocations) {
          const coordinates = location.realCoordinates || location.coordinates;
          const coordinateKey = `${coordinates.lat}-${coordinates.lng}`;
          if (seenCoordinates.has(coordinateKey)) {
            const offset = 0.0001;
            location.realCoordinates = {
              lat: coordinates.lat,
              lng: coordinates.lng + offset,
            };
          }
          seenCoordinates.add(coordinateKey);
          adjustedLocations.push(location);
        }
        return adjustedLocations.map(location => {
          let icon = location.locationType === LocationType.PV ? this.pointPVIcon : this.pointHPIcon
          if(location.locationType === LocationType.PV && location.clientType === 'BIZ'){
            icon = this.pointPVSecondaryIcon
          }
          return {
            ...location,
            icon,
          }
        });
      },
      notFetchedLocations() {
        return this.selectedLocations.filter(location => !location.fetched)
      }
    },
    components: {
      Logos,
    },
    data(){
      return{
        ApiGoogleMaps,
        map: null,
        position: {
          lat: 52,
          lng: 19.3,
        },
        googleListeners: [],
        currentlyOpenedLocationId: null,
        zoom: 7,
        infoWindowPos: {
          lat: 0,
          lng: 0,
        },
        infoWinOpen: false,
        currentMidx: null,
        infoOptions: {
          pixelOffset: {
            width: 0,
            height: -35
          }
        },
        boundChangeDebounceSymbol: null,
        searchAddressInput: '',
        locations: [],
        fetched: false,
        popupUse: false,
        timeoutHidePopupUse: null,
        currentMapTypeId: 'terrain',
        mapStyle: mapStyle
      }
    },
    methods: {
      increaseZoom(location){
        if(this.zoom > 5) {
          this.zoom = this.zoom + 2
        }
        else{
          this.zoom = this.zoom + 1
        }
        this.map.panTo({lat: location.coordinates.lat, lng: location.coordinates.lng})
      },
      onZoomChange(e) {
        this.zoom = e
      },
      closeInfo() {
        this.currentlyOpenedLocationId = null
      },
      async onBoundChange() {
        const actualSymbol = Symbol()
        this.boundChangeDebounceSymbol = actualSymbol
        await new Promise((resolve) => {
          setTimeout(resolve, 200)
        })
        if(this.boundChangeDebounceSymbol !== actualSymbol) {
          return
        }
        const bounds = this.map.getBounds()
        const ne = bounds.getNorthEast()
        const sw = bounds.getSouthWest()
        const resCoords = await ApiGoogleMaps.getLocations({
          latMin: sw.lat(),
          latMax: ne.lat(),
          lngMin: sw.lng(),
          lngMax: ne.lng(),
          zoom: this.zoom,
        })

        if(this.boundChangeDebounceSymbol !== actualSymbol) {
          return
        }
        const previouslyFetchedLocations = this.locations.filter(location => location.fetched)
        const newLocations = resCoords.locations.map(location => {
          return {
            coordinates: {
              lng: location.geometry.coordinates[0],
              lat: location.geometry.coordinates[1],
            },
            id: location.properties.id || location.id,
            isCluster: location.properties.isCluster,
            pointCount: location.properties.pointCount,
            locationType: location.locationType,
            clientType: location.clientType,
          }
        }).filter(location => {
          return !previouslyFetchedLocations.find(e => e.id === location.id && !e.isCluster)
        })
        newLocations.push(...previouslyFetchedLocations)
        this.locations = newLocations
      },
      isLocationInfoFetched(id) {
        return this.locations.find(location => (location.id === id) && (location.fetched === true))
      },
      async fetchLocation(id){
        const res = await ApiGoogleMaps.getLocation({id, token: this.$route.query.token})
        if(!res.success){
          clearTimeout(this.timeoutHidePopupUse)
          this.currentlyOpenedLocationId = null
          this.popupUse = true
          return this.timeoutHidePopupUse = setTimeout(() => {
            this.popupUse = false
          }, 3000)
        }
        const location = res.data.location
        const oldCoordinates = this.locations.find(location => location.id === id).coordinates
        this.locations = this.locations.map(oldLocation => {
          if((oldLocation.coordinates.lat === oldCoordinates.lat && oldLocation.coordinates.lng === oldCoordinates.lng) && oldLocation.id !== id) {
            return {
              ...oldLocation,
              realCoordinates: {
                lat: location.lat,
                lng: location.lng,
              },
            }
          }
          if(oldLocation.id !== id) {
            return oldLocation
          }
          return {
            ...oldLocation,
            power: location.power,
            realCoordinates: {
              lat: location.lat,
              lng: location.lng,
            },
            fetched: true,
          }
        })
      },
      toggleInfoWindow(locationId) {
        if (this.currentlyOpenedLocationId === locationId) {
          return this.currentlyOpenedLocationId = null;
        }
        this.currentlyOpenedLocationId = locationId;
        if (this.isLocationInfoFetched(locationId)) {
          return
        }
        this.fetchLocation(locationId)
      },
      GPS() {
        this.$getLocation({})
          .then(coordinates => {
            this.map.panTo({lat: coordinates.lat, lng: coordinates.lng})
            this.map.setZoom(14)
          })
          .catch(error => alert(error))
      },
      updatePlace(place){
        this.map.panTo({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()})
        this.map.setZoom(15)
      },
      onMapTypeIdChange() {
        this.currentMapTypeId = this.map.getMapTypeId()
      },
      async mountMap() {
        this.map = await this.$refs.mapRef.$mapPromise
        this.mountListeners()
        this.onBoundChange()
      },
      mountListeners() {
        this.googleListeners = [
          this.map.addListener("bounds_changed", () => {
            this.onBoundChange()
          }),
          this.map.addListener("zoom_changed", () => {
            this.onBoundChange()
          }),
          this.map.addListener("center_changed", () => {
            this.onBoundChange()
          }),
          this.map.addListener("maptypeid_changed", () => {
            this.onMapTypeIdChange()
          })
        ]
      },
      togglePopupUse(){
        this.popupUse = !this.popupUse
      },
    },
    mounted() {
      this.mountMap()
    },
    beforeDestroy(){
      for(let listener of this.googleListeners) {
       this.google.maps.event.removeListener(listener)
      }
    }
  }
</script>

<style lang="scss" scoped>

  *{
    margin: 0;
    padding: 0;
  }

  .marker{
    height: 64px;
    width: 64px;
  }

  .power{

  }

  ::v-deep .photos{
    display: flex;
    width: 420px;
    justify-content: space-around;
    padding-bottom: 5px;

  }

  ::v-deep .photo1{
    background-color: #999999;
    width: 130px;
    height: 80px;
    border-radius: 10px;
    box-shadow: 0 0 4px 2px rgba(0,0,0,0.1);
  }
 ::v-deep .photo2{
    background-color: bisque;
    width: 130px;
    height: 80px;
    border-radius: 10px;
    box-shadow: 0 0 4px 2px rgba(0,0,0,0.1);
  }
  ::v-deep .photo3{
    background-color: darkgoldenrod;
    width: 130px;
    height: 80px;
    border-radius: 10px;
    box-shadow: 0 0 4px 2px rgba(0,0,0,0.1);
  }
  ::v-deep .vue-map-hidden {
    display: block;
  }
  ::v-deep .backgroundGps{
    position: fixed;
    padding: 6px;
    bottom: 200px;
    right: 10px;
    background-color: #fff;
    width: 28px;
    height: 28px;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    cursor: pointer;
  }
  ::v-deep .gmnoprint {
    right: 100px;
  }
  ::v-deep .GPS{
    position: absolute;
    background-image: url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png);
    background-size: 180px 18px;
    background-position: 0 0;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    margin: 5px;
  }
  ::v-deep .search{
    background: none rgb(255, 255, 255);
    border: 0;
    margin: 10px;
    padding: 0;
    text-transform: none;
    position: absolute;
    border-radius: 2px;
    height: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 1px 4px -1px;
    overflow: hidden;
    top: 0;
    width: 320px;
    text-align: center;
    left: calc(50% - 160px);

    &:focus {
      outline: 0;
    }
  }
  .popup-use {
    width: calc(100% - 60px);
    position: fixed;
    top: 100%;
    background: #86179d;
    color: white;
    padding: 8px 12px;
    transform: translate(-50%);
    left: 50%;
    max-width: 420px;
    transition: all .25s ease;
    text-align: center;
    opacity: 0;
    z-index: 3;
    &.show {
      opacity: 1;
      transform: translate(-50%, calc(-100% - 30px));
    }
  }
  ::v-deep .close {
    position: absolute;
    display: flex;
    font-size: 24px;
    top: 18px;
    right: 20px;
    padding: 10px;
    cursor: pointer;
    opacity: 0.66;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }
    ::v-deep .search{
      position: absolute;
      display: flex;
      top: 20px;
      left: 50%;
      padding: 10px;
    }
  }

  .logo-wrapper::v-deep {
    position: fixed;
    bottom: 0;
    pointer-events: none;
    z-index: 2;
    width: 100%;
    padding: 30px 0;

  }
</style>
